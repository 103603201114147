.center-card {
  align-items: center;
  justify-content: center;
}

.collection-item-small-padding {
  padding: 0.5rem !important;
}

.flex-container {
  display: flex;
  flex-direction: row;
}
.flex-container-col {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 2rem 0 2rem;
}

.flex-nav-align {
  justify-content: space-between;
}

.forgot-msg-text {
  display: inline-block;
  font-size: 1.5rem;
  width: 95%;
  margin-bottom: 2rem;
}

.flex-1 {
  flex-grow: 1;
}

.general-font {
  font-size: 1.5rem;
}
.general-text-padding {
  padding: 0.5rem;
}
.group-header {
  height: 10%;
  width: 100%;
}
.icon-padding {
  width: 40px;
}

.list-item-font {
  font-size: 1.2rem;
}

.row-small {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.switch-wrapper {
  display: flex;
  justify-content: flex-end;
}
.switch-padding {
  justify-content: center;
}

.scroll-overflow {
  overflow: scroll;
  max-height: 45rem;
}
.scroll-overflow-group {
  overflow: scroll;
  height: 100%;
}
.square {
  position: absolute;
  width: 60px;
  height: 60px;
  overflow: hidden;
  left: 1rem;
  display: inline-block;
  vertical-align: middle;
  padding-right: 0.5rem;
}
.table-container {
  display: flex;
  flex-direction: column;
  height: 65%;
}

.text-box {
  height: 7rem;
  padding: 0.5rem;
}

.width-100 {
  width: 100%;
}
.width-90 {
  width: 90%;
}

.width-85 {
  width: 85%;
}

.note-padding {
  padding: 0.5rem;
}

.note-text {
  font-size: 1rem;
}
