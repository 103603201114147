.left_nav {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.hover_green:hover {
  background-color: #c5cae9;
}

.collection-item:hover {
  background-color: green;
}
